<template>
  <v-app>
    <Header></Header>
    <v-card
        elevation="10"
        outlined
        class="card"

    >
      <v-list-item-title class="content">
        <h1><b>About Us</b></h1>
      </v-list-item-title>
      <div class="content">
        Welcome to <router-link to="/" style="text-decoration: none;"><b>shopactreg.in</b></router-link> .<br><br>
        We have launched shopactreg.in with the aim of serving our clients with best and reliable online assistance for
        shop act registration or gumasta certificate in India.<br><br>
        Our experts apply strategic thinking, dedicated support, complete efforts and practical approach.<br><br>
        Our motive is to help the entrepreneur on the legal and regulatory requirements, and be a partner throughout the
        business lifecycle, offering support at every stage to ensure the business remains compliant and continually
        growing.<br><br>
        We hereby declare to the user that we are only a Consultancy Firm providing consultancy services with the
        intension to help people to process their shop act registration or gumasta certificate and they shall provide
        their personal data in acceptance of the said fact and their relationship with the website shall be governed by
        the terms & conditions, privacy policy, refund policy and disclaimer displayed on the website.<br><br>
        <b>Note: shopactreg.in is claimed and worked by a firm and not associated to any administration body or ministry of
        the government.</b><br><br>
      </div>
    </v-card>
    <br>
    <v-divider></v-divider>
    <br>
    <Footer></Footer>
    <div class="text-center">
    </div>
  </v-app>

</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";

export default {
  name: "AboutUs",
  components: {Footer, Header}
}
</script>

<style scoped>

.content {
  text-align: center;
}

.card {
  max-width: 65vw;
  position: relative;
  left: 20%;
  padding: 10px;
}

@media only screen and (max-width: 1200px) {
  /*Tablets [601px -> 1200px]*/
}

@media only screen and (max-width: 600px) {

  .card{
    max-width: 95vw;
    position: relative;
    left: 2%;
    padding: 10px;
  }
}

</style>